/* Navbar.css */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
}

.navbar {
  background-color: #1f2937; /* A deeper gray */
  color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 1rem 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  transition: all 0.3s ease-in-out;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.navbar-logo {
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
  color: #f87171;
}

.navbar-menu {
  list-style: none;
  display: flex;
  gap: 2rem;
  margin: 0;
  padding: 0;
}

.navbar-link {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 500;
  position: relative;
  padding: 0.5rem 0;
  transition: color 0.3s ease, transform 0.3s ease;
}

.navbar-link:hover {
  color: #f87171;
  transform: scale(1.05); /* Slight zoom on hover */
}

.navbar-link::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #f87171;
  transition: width 0.3s;
  position: absolute;
  bottom: 0;
  left: 0;
}

.navbar-link:hover::after {
  width: 100%;
}

.navbar-toggle {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 1.8rem;
  cursor: pointer;
}

.navbar-toggle-icon {
  font-size: 1.8rem;
}

.navbar-menu-open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 70px;
  right: 1rem;
  background-color: #1f2937;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  gap: 1rem;
  animation: slideDown 0.3s ease;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .navbar-menu {
    display: none; /* Hide menu items on mobile */
  }

  .navbar-toggle {
    display: block; /* Show toggle on mobile */
  }

  .navbar-menu.navbar-menu-open {
    display: flex; /* Show menu when open on mobile */
  }
}
