/* Section Styling */
.discord-bot-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem 20px;
    background-color: #374151; /* Dark background to match the theme */
  }
  
  .discord-bot-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    gap: 50px;
  }
  
  .discord-bot-info {
    flex: 1;
    max-width: 600px;
    color: #f9fafb; /* Light text color */
  }
  
  .section-title {
    font-size: 3rem; /* Larger title for better emphasis */
    margin-bottom: 1.5rem; /* Space between title and content */
    color: #f9fafb;
    text-align: left;
    font-weight: 700;
  }
  
  .section-description {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 2rem;
    color: #e5e7eb; /* Lighter gray color for better contrast */
    text-align: left;
    max-width: 700px; /* Control width for better alignment */
  }
  
  .discord-bot-features {
    margin-bottom: 2rem;
  }
  
  .discord-bot-features ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .discord-bot-features li {
    font-size: 1.2rem;
    margin: 1rem 0;
    padding: 1rem;
    background-color: #4b5563; /* Dark background for features */
    border-radius: 8px;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
  }
  
  .discord-bot-features li:hover {
    background-color: #f87171; /* Hover effect to match button color */
    color: white;
  }
  
  .feature-icon {
    margin-right: 1rem;
    font-size: 1.5rem;
  }
  
  .invite-button {
    display: inline-block;
    background-color: #f87171; /* Light red to match the hover effect */
    color: white;
    padding: 15px 30px;
    font-size: 1.2rem;
    border-radius: 8px;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-top: 20px;
  }
  
  .invite-button:hover {
    background-color: #e66464; /* Slightly darker red on hover */
    transform: translateY(-3px); /* Slight elevation effect */
  }
  
  .discord-bot-image {
    flex: 1;
    max-width: 500px;
  }
  
  .discord-bot-image img {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Subtle shadow for the image */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .discord-bot-container {
      flex-direction: column;
      text-align: center;
      align-items: center;
    }
  
    .discord-bot-info {
      max-width: 100%;
    }
  
    .discord-bot-image img {
      width: 100%;
      max-width: 400px;
      margin-top: 30px;
    }
  
    .section-title {
      font-size: 2.5rem;
    }
  
    .section-description {
      font-size: 1rem;
    }
  
    .discord-bot-features li {
      font-size: 1rem;
    }
  
    .invite-button {
      font-size: 1.1rem;
      padding: 12px 25px;
    }
  }  