.footer {
    background-color: #1f2937; /* A deeper gray */
    color: white;
    text-align: center;
    position: fixed;
    width: 100%;
    bottom: 0;
    transition: all 0.3s ease-in-out;
  }
  
  .footer p {
    margin: 0.5rem 0; /* Spacing between paragraphs */
  }
  
  .footer a {
    color: #f87171; /* Light red color for links */
    text-decoration: none; /* Remove underline from links */
    margin-left: 0.5rem; /* Space between text and link */
  }
  
  .footer a:hover {
    text-decoration: underline; /* Underline on hover */
  }
  