/* App.css */

body, html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth; /* Smooth scrolling for in-page navigation */
}

.section {
  height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  color: white;
}

#home {
  background-color: #374151; /* Grayish background for each section */
}

#about {
  background-color: #4b5563; /* Section background */
  padding: 4rem 1rem; /* Add padding for spacious layout */
  display: flex; /* Flexbox for alignment */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center align children */
}

#about h1 {
  font-size: 2.8rem; /* Slightly larger heading */
  margin-bottom: 1.5rem; /* More space below heading */
  color: #f9fafb; /* Light color for better contrast */
  text-align: center; /* Centered heading */
}

#about p {
  font-size: 1.2rem; /* Consistent size for description */
  max-width: 800px; /* Restrict width for better alignment */
  line-height: 1.6; /* Improved line height */
  text-align: center; /* Center align text */
  margin: 0.5rem 0 2.5rem; /* Spacing between paragraphs */
  color: #e5e7eb; /* Light gray color */
  padding: 0 1rem; /* Add some horizontal padding for better appearance */
}

#about .values-list {
  list-style-type: none; /* Remove default list styles */
  padding: 0; /* Reset padding */
  margin: 0; /* Reset margin */
  max-width: 800px; /* Restrict width for better alignment */
}

#about .values-list li {
  font-size: 1.2rem; /* Consistent font size for list items */
  margin: 1rem 0; /* Vertical spacing between items */
  padding: 1.5rem; /* More padding for list items */
  background-color: #374151; /* Slightly darker background for list items */
  border-radius: 8px; /* Rounded corners for a modern feel */
  transition: background-color 0.3s ease; /* Smooth transition on hover */
  display: flex; /* Flexbox for alignment */
  align-items: center; /* Center align items */
}

#about .values-list li:hover {
  background-color: #f87171; /* Light red color on hover */
  color: white; /* Change text color on hover */
}

#about .values-list li span {
  font-size: 2rem; /* Larger icon size */
  margin-right: 1rem; /* Space between icon and text */
}

#about .values-list li strong {
  font-size: 1.5rem; /* Larger project name size */
  color: #f9fafb; /* Light color for project names */
}

#about .values-list li p {
  margin-top: 0.25rem; /* Space above descriptions */
  color: #e5e7eb; /* Light color for descriptions */
  font-size: 1rem; /* Description font size */
  line-height: 1.4; /* Adjust line height for better readability */
}

#services {
  background-color: #6b7280;
}

#contact {
  background-color: #9ca3af;
}

.section h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.section p {
  font-size: 1.5rem; /* Increased font size for better readability */
  max-width: 600px;
  line-height: 1.6;
}

/* Contact Form Styling */
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #2d3748; /* Slightly darker background */
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

form input,
form textarea {
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  border: 2px solid transparent;
  border-radius: 5px;
  background-color: #f7fafc;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  font-family: 'Poppins', sans-serif;
}

form input:focus,
form textarea:focus {
  border-color: #f87171; /* Light red color on focus */
  outline: none;
  box-shadow: 0 0 8px rgba(248, 113, 113, 0.5); /* Soft red shadow on focus */
}

form textarea {
  resize: vertical;
  min-height: 150px;
}

form button {
  padding: 0.8rem 2rem;
  font-size: 1rem;
  color: white;
  background-color: #f87171;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

form button:hover {
  background-color: #e66464; /* Slightly darker red on hover */
  transform: scale(1.05); /* Slight grow effect on hover */
}

form button:active {
  transform: scale(0.98); /* Slight shrink effect on click */
}